import React from 'react';
import Layout from '../../../templates/Main'
import { Flex } from 'rebass/styled-components'
import Heading from '../../../modules/Heading'
import UploadModal from '../../../modules/UploadModal'


const Modal =({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h3' text='Upload' />
            <Flex justifyContent="center" bg='charcoal' width='100%'>
                <UploadModal />
            </Flex>
        </Layout>
    );
}

export default Modal
